import React from 'react'
import styles from './MyWork.module.css'
function MyWork() {
  return (
    <div className={styles['container']}>
      <div className={styles['my-work-col']}>
        <div className={styles['my-work-pic']}>
          <img src="https://via.placeholder.com/150" alt="" />
        </div>
        <div className={styles['my-work-text']}>THis is where my info on the pictue shown</div>
      </div>
      <div className={styles['my-work-col']}>
        <div className={styles['my-work-pic']}>
          <img src="https://via.placeholder.com/150" alt="" />
        </div>
        <div className={styles['my-work-text']}>THis is where my info on the pictue shown</div>
      </div>
      <div className={styles['my-work-col']}>
        <div className={styles['my-work-pic']}>
          <img src="https://via.placeholder.com/150" alt="" />
        </div>
        <div className={styles['my-work-text']}>THis is where my info on the pictue shown</div>
      </div>
    </div>
  )
}
export default MyWork
