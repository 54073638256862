import React from 'react'

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">
        RD Same Controls
      </a>
    </nav>
  )
}
export default NavBar
