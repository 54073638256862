import React, { useReducer } from 'react'
import NavBar from '../../components/NavBar'
import ModalComponent from '../../components/Modal'
import Button from 'react-bootstrap/Button'
import MyWork from '../MyWork/MyWork'
function HomePage() {
  const [state, dispatch] = useReducer(reducer, { show: false })
  const { show } = state
  function reducer(payload, previousState) {
    const state = { ...payload, ...previousState }
    console.log('Styate', state)
    return state
  }
  const modalChildren = <div>Modal Children</div>
  return (
    <>
      <NavBar />
      <div className="container">
        <h1>Home Page</h1>
        <Button onClick={() => dispatch({ show: true })}>Show Modal</Button>
        <ModalComponent show={show} handleClose={() => dispatch({ show: false })}>
          {modalChildren}
        </ModalComponent>
        <MyWork />
      </div>
    </>
  )
}
export default HomePage
