import React from 'react'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'

function ModalComponent({ show, handleClose, children }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton> Contact Me!</Modal.Header>
        <Modal.Body> {children}</Modal.Body>
      </Modal>
    </>
  )
}
ModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default ModalComponent
