import React from 'react'
import HomePage from './pages/Homepage/HomePage'
import styles from './App.module.css'
function App() {
  return (
    <div className={styles['app']}>
      <HomePage />
    </div>
  )
}

export default App
